import React, { useEffect, useState } from "react";
import Header from "./components/Header/Header";
import "./App.css";
import LandingPage from "./pages/LandingPage/LandingPage";
import Footer from "./components/Footer/Footer";
import apiClient from "./services";
import {
  FooterData,
  FooterTexts,
  WelcomeContent,
  WelcomeContentKeys,
  FooterTranslationCodes,
  FooterText,
} from "./utils/data/FooterData";
import LoadingOverlay from "react-loading-overlay";
const App = () => {
  LoadingOverlay.propTypes = undefined;
  const [footerLabels, setFooterLabels] = useState({});
  const [footerTranslationValue, setFooterTranslationValue] = useState({
    label: "English",
    value: 1,
  });
  const [loader, setLoader] = useState(false);
  const [welcomeContent, setWelcomeContent] = useState([]);

  useEffect(() => {
    getFooterLabels();
  }, []);

  const getFooterLabels = async (translationLanguage = "English") => {
    setLoader(true);
    await apiClient
      .get(
        `/commonportal-retriever/getTranslatedFooter/${FooterTranslationCodes[translationLanguage]}/$$Provider%7C$$Legal_Notices%7C$$Cookies%7C$$Privacy%7C$$Contact%7C$$To_the_top%7C$$ownersManual_welcome_description%7C$$emergencyPortal_welcome_description%7C$$welcome_text`
      )
      .then((response) => {
        responseSplitter(response.data);
        // setFooterLabels(response.data.footerDetails);
      })
      .catch((err) => {
        console.error(err);
      });
    setLoader(false);
  };

  const responseSplitter = (response) => {
    let footerArr = [];
    let welcomeObj = [];
    response.footerDetails.map((item) => {
      if (Object.keys(FooterText).includes(item.Variable_Name)) {
        let attachURL = FooterText[item.Variable_Name]?.url;

        let newObj = { ...item, attachURL };
        console.log("AttachURl", newObj);
        footerArr.push(newObj);
      } else {
        welcomeObj.push(item);
      }
    });

    setFooterLabels(footerArr);
    setWelcomeContent(welcomeObj);

    console.log(footerArr, welcomeObj);
  };

  // if (loader) {
  //   return <LoadingOverlay active={true} spinner />;
  // }

  var labelsAsObject =
    footerLabels.length > 0 &&
    footerLabels?.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.Variable_Name]: item.translated_text }),
      {}
    );

  return (
    <div className="container">
      {loader ? (
        <LoadingOverlay active={true} spinner />
      ) : (
        <>
          <Header headerLabels={labelsAsObject} />
          <LandingPage welcomeContent={welcomeContent} />
          <Footer
            footerLabels={footerLabels}
            getFooterLabels={getFooterLabels}
            footerTranslationValue={footerTranslationValue}
            setFooterTranslationValue={setFooterTranslationValue}
          />
        </>
      )}

      {/* {Object.keys(footerLabels).length > 0 && (
        <>
          <Header />
          <LandingPage welcomeContent={welcomeContent} />
          <Footer
            footerLabels={footerLabels}
            getFooterLabels={getFooterLabels}
            footerTranslationValue={footerTranslationValue}
          />
        </>
      )} */}
    </div>
  );
};

export default App;
