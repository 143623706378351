import React from "react";

import DesktopBreakpoint from "../../utils/responsive/DesktopBreakpoint";
import PhoneBreakpoint from "../../utils/responsive/PhoneBreakpoint";
import TabletBreakpoint from "../../utils/responsive/TabletBreakpoint";
import "./Header.css";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Divider, Stack, Link } from "@mui/material";

const Header = ({ headerLabels }) => {
  return (
    <>
      <Box px={5}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar
            sx={{
              justifyContent: { xs: "space-between", md: "space-between" },
              paddingTop: "22px",
            }}
          >
            <Box>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <img
                  className="logo-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/Wortmarke_Stern-3.svg"
                  }
                  alt="Logo"
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "none", lg: "none" },
                color: "#ffffff",
              }}
            >
              <a
                style={{ paddingRight: 5 }}
                href="https://www.mercedes-benz-trucks.com/en_GB/home.html"
                target="_blank"
              >
              <img
                src={process.env.PUBLIC_URL + "/assets/images/info_icon.png"}
                alt="Logo"
                height={15}
              />
              </a>
            </Box>

            <Box
              sx={{ flexGrow: 1 }}
              display={{ xs: "none", md: "block" }}
            ></Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex", lg: "flex" },
                alignItems: "center",
              }}
            >
              <a
                style={{ paddingRight: 5 }}
                href="https://www.mercedes-benz-trucks.com/en_GB/home.html"
                target="_blank"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  // className="header-text"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/to_home.png"}
                    alt="Logo"
                    height={15}
                    className="header-mb-img"
                    // color="#999"
                  />
                  <Typography
                    component="span"
                    // color="red"
                    px={2}
                    sx={{
                      // color: "#fff",
                      fontSize: 14,
                      fontFamily: "MBCorpoSText-Regular",
                      // color: "#999",
                      padding: 0.5
                    }}
                  >
                    Mercedes-Benz Trucks
                  </Typography>
                </Box>
              </a>

              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                color="#999"
                sx={{
                  height: 15,
                  marginRight: 2,
                  width: 2,
                  fontFamily: "MBCorpoSText-Regular",
                }}
              ></Divider>

              <a
                style={{ paddingRight: 5 }}
                href="https://www.mercedes-benz-trucks.com/en_GB/home.html"
                target="_blank"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/info_icon.png"
                    }
                    alt="Logo"
                    height={14}
                    // className="header-mb-img"
                  />
                  <Typography
                    component="span"
                    // color="red"
                    px={2}
                    sx={{
                      // color: "#fff",
                      fontSize: 14,
                      fontFamily: "MBCorpoSText-Regular",
                      // color: "#999",
                      padding: 0.5
                    }}
                  >
                    {headerLabels?.$$Provider}
                  </Typography>
                </Box>
              </a>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default Header;
