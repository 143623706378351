// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-card {
  cursor: pointer;
}

.tile-text {
  font-family: "MBCorpoSTitle-Regular";
  line-height: 1.25rem;
  letter-spacing: 0.0125em;
}

.tile:hover {
  /* background: #494949; */
  /* border-radius: 2px solid red; */
}

.tile:hover .tile-text {
  /* color: #00adef; */
}

/* .tile:hover .tooltip {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(199deg)
    brightness(120%) contrast(120%);
} */

.tooltip {
  filter: brightness(0.5);
}
`, "",{"version":3,"sources":["webpack://./src/pages/LandingPage/LandingPage.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,oBAAoB;EACpB,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;EACzB,kCAAkC;AACpC;;AAEA;EACE,oBAAoB;AACtB;;AAEA;;;GAGG;;AAEH;EACE,uBAAuB;AACzB","sourcesContent":[".image-card {\n  cursor: pointer;\n}\n\n.tile-text {\n  font-family: \"MBCorpoSTitle-Regular\";\n  line-height: 1.25rem;\n  letter-spacing: 0.0125em;\n}\n\n.tile:hover {\n  /* background: #494949; */\n  /* border-radius: 2px solid red; */\n}\n\n.tile:hover .tile-text {\n  /* color: #00adef; */\n}\n\n/* .tile:hover .tooltip {\n  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(199deg)\n    brightness(120%) contrast(120%);\n} */\n\n.tooltip {\n  filter: brightness(0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
