export const FooterData = {
  footerDetails: [
    {
      Variable_Name: "$$Cookies",
      translated_text: "Cookies",
    },
    {
      Variable_Name: "$$Provider",
      translated_text: "Provider",
    },
    {
      Variable_Name: "$$Legal_Notices",
      translated_text: "Legal Notices",
    },
    {
      Variable_Name: "$$To_the_top",
      translated_text: "To the top",
    },
    {
      Variable_Name: "$$Privacy",
      translated_text: "Privacy",
    },
    {
      Variable_Name: "$$Declaration_Conformity",
      translated_text: "Declaration of Conformity/FOSS",
    },
    {
      Variable_Name: "$$Contact",
      translated_text: "Contact",
    },
  ],
};

export const FooterTranslationDropdownValues = [
  { label: "English", value: 1 },
  { label: "Deutsch", value: 2 },
];

export const FooterTranslationCodes = {
  English: "en_GB",
  Deutsch: "de_DE",
};

export const WelcomeContent = {
  welcome_text: "Welcome to Mercedes-Benz Trucks.",
  ownersManual_welcome_description:
    "You will find operator’s manuals and supplements for your vehicle on our Truck Guides portal.",
  emergencyPortal_welcome_description:
    "You can find the rescue card and important information for emergency services on our emergency portal.",
};

export const FooterTexts = [
  "$$Cookies",
  "$$Provider",
  "$$Legal_Notices",
  "$$To_the_top",
  "$$Privacy",
  // "$$Declaration_Conformity",
  "$$Contact",
];

export const FooterText = {
  $$Cookies: {
    url: "https://www.mercedes-benz-trucks.com/en_GB/home.html",
  },
  $$Provider: { url: "https://www.mercedes-benz-trucks.com/en_GB/home.html" },
  $$Legal_Notices: {
    url: "https://www.mercedes-benz-trucks.com/en_GB/home.html",
  },
  // $$To_the_top: { url: "" },
  $$Privacy: { url: "https://www.mercedes-benz-trucks.com/en_GB/home.html" },
  $$Contact: { url: "https://www.mercedes-benz-trucks.com/en_GB/home.html" },
};
export const WelcomeContentKeys = [
  "$$welcome_text",
  "$$ownersManual_welcome_description",
  "$$emergencyPortal_welcome_description",
];
