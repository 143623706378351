import React, { useEffect, useState } from "react";
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
} from "react-select";
import { Divider, Stack, Paper, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  FooterData,
  FooterTranslationDropdownValues,
} from "../../utils/data/FooterData";
import "./Footer.css";

const customStyles = {
  option: (provided) => ({
    ...provided,
    color: "black",
  }),
  control: (provided) => ({
    ...provided,
    color: "black",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "black",
  }),
};

const Footer = ({
  footerLabels,
  getFooterLabels,
  footerTranslationValue,
  setFooterTranslationValue,
}) => {
  console.log("footerLabels", footerLabels);
  const [languageTranslation, setLanguageTranslation] = useState(
    footerTranslationValue
  );

  const handleLangChange = (event) => {
    setLanguageTranslation({ label: event.labe });
    getFooterLabels(event.label);
    setFooterTranslationValue({
      label: event.label,
      value: event.value,
    });
  };

  return (
    <footer style={{ marginTop: "auto", color: "#ffffff", marginBottom: 20 }}>
      <Box px={3}>
        <Grid container alignItems="center">
          <Grid xs={12} md={2}>
            <Select
              styles={customStyles}
              maxMenuHeight={200}
              menuPlacement="auto"
              className="react-select dropdown-fonts"
              isDisabled={false}
              isSearchable={false}
              onChange={(event) => handleLangChange(event)}
              value={languageTranslation}
              options={FooterTranslationDropdownValues}
            />
          </Grid>
          <Grid xs={12} md={9}>
            <Grid
              container
              sx={(theme) => ({
                justifyContent: "center",
                alignItems: "center",
                fontSize: 12,
                [theme.breakpoints.down("sm")]: {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 12,
                  lineHeight: 2,
                },
                [theme.breakpoints.down("xs")]: {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 12,
                  lineHeight: 2,
                },
                [theme.breakpoints.down("md")]: {
                  display: "flex",
                  flexDirection: "column",
                  fontSize: 12,
                  lineHeight: 2,
                },
              })}
            >
              {Object.keys(footerLabels)?.map((item, index) => (
                <Grid
                  key={index}
                  style={{
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                  xs={12}
                  md={12}
                >
                  <a
                    style={{
                      paddingRight: 5,
                      // color: "#fff",
                      fontFamily: "MBCorpoSText-Regular",
                    }}
                    className={`${index == 0 ? "" : "link"}`}
                    href={footerLabels[item]?.attachURL}
                    target="_blank"
                  >
                    {footerLabels[item]?.translated_text}
                  </a>
                </Grid>
              ))}
            </Grid>
            {/* <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                fontSize: 10,
              }}
            >
              {Object.keys(FooterData?.footerDetails).map((item) => (
                <p style={{ paddingRight: 20 }}>
                  {FooterData?.footerDetails[item].translated_text}
                </p>
              ))}
            </Box> */}
          </Grid>
          {/* <Grid
            Paper
            xs={12}
            md={1}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Box>To Top</Box>
          </Grid> */}
        </Grid>
      </Box>
      {/* <Box px={3}>
        <Stack direction="row" spacing={6}>
          <Box>
            <Select
              maxMenuHeight={200}
              menuPlacement="auto"
              className="react-select"
              isDisabled={false}
              isSearchable={false}
              // onChange={(event) => handleLangChange(event)}
              // value={selectedLanguage}
              options={[
                { label: "en_GB", value: 1 },
                { label: "de_DE", value: 2 },
              ]}
            />
          </Box>

          <Box>
            {Object.keys(FooterData?.footerDetails).map((item) => (
              <div>{FooterData?.footerDetails[item].translated_text}</div>
            ))}
          </Box>

          <Box>To Top</Box>
        </Stack>
      </Box> */}

      {/* <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={responsivePaper}>
            <Select
              maxMenuHeight={200}
              menuPlacement="auto"
              className="react-select"
              isDisabled={false}
              isSearchable={false}
              // onChange={(event) => handleLangChange(event)}
              // value={selectedLanguage}
              options={[
                { label: "en_GB", value: 1 },
                { label: "de_DE", value: 2 },
              ]}
            />
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              fontSize: 10,
              width: "80%",
            }}
            sx={responsivePaper}
          >
            {Object.keys(FooterData?.footerDetails).map((item) => (
              <p style={{ paddingRight: 20 }} sx={responsivePaper}>
                {FooterData?.footerDetails[item].translated_text}
              </p>
            ))}
          </Box>

          <Box >To Top</Box>
        </Box>
      </Stack> */}
    </footer>
  );
};

export default Footer;
