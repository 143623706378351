// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link::before {
  content: " | ";
  margin-right: 12px;
  filter: grayscale(1);
}

@media only screen and (max-width: 992px) {
  .link::before {
    content: "";
    margin-right: 0px;
    filter: grayscale(1);
  }
}

.dropdown-fonts {
  font-family: "MBCorpoSText-Regular";
}
a {
  text-decoration: none;
  line-height: 1.25rem;
  letter-spacing: 0.0125em;
}

a:link,
a:visited {
  color: #999999;
}

a:hover {
  color: #00adef;
}

a:hover link::before {
  color: #999999 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE;IACE,WAAW;IACX,iBAAiB;IACjB,oBAAoB;EACtB;AACF;;AAEA;EACE,mCAAmC;AACrC;AACA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,wBAAwB;AAC1B;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".link::before {\n  content: \" | \";\n  margin-right: 12px;\n  filter: grayscale(1);\n}\n\n@media only screen and (max-width: 992px) {\n  .link::before {\n    content: \"\";\n    margin-right: 0px;\n    filter: grayscale(1);\n  }\n}\n\n.dropdown-fonts {\n  font-family: \"MBCorpoSText-Regular\";\n}\na {\n  text-decoration: none;\n  line-height: 1.25rem;\n  letter-spacing: 0.0125em;\n}\n\na:link,\na:visited {\n  color: #999999;\n}\n\na:hover {\n  color: #00adef;\n}\n\na:hover link::before {\n  color: #999999 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
